import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from '../Title';
import { FPXRequestReport, ExtraMap, Utm } from '../../types';
import { useStore } from '../../Store';
import { PaymentMethod } from '../../datadictionary';
import CallbackLink from '../CallbackLink';
import { FormatCurrency } from '../../utils';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    smaller: {
      padding: 2,
    },
  })
);

interface Props {
  data: FPXRequestReport[];
  onClick?: (order_id: string) => void;
}

const DefaultReport: React.FC<Props> = ({ data }) => {
  const classes = useStyles();
  const { state } = useStore();

  if (state!.featureflags === undefined) {
    return (<React.Fragment></React.Fragment>);
  };
  const showAkaunNo = state!.featureflags!.dashboard!.showAkaunNo;
  const showCallbacks = state!.featureflags!.dashboard!.showCallbacks;
  const showExtraData = state!.featureflags!.dashboard!.showExtraData;
  const showCharges = state!.featureflags!.dashboard!.showCharges;
  const showOrderChan = state!.featureflags!.dashboard!.showOrderChan;
  // let showUtm = state!.featureflags!.dashboard!.showOrderChan;
  let showUtm = false;

  if (showUtm) {
    let data2 = data;
    let i = 0;
    for (i = 0; i < data2.length; i++) {
      if (data2[i].extra == null) {
        data2[i].extra = {
          utm: {} as Utm
        } as ExtraMap;
      }  
        
      if (data2[i]!.extra!.utm === undefined) {
        data2[i]!.extra!.utm = {
        } as Utm;
      } else {
        // console.log("x",data2[i]!.extra!.utm);
      }
    }
  }
  // ShowOrderChan
  // const selectView =  state!.featureflags!.dashboard!.selectView;
  // const view = state!.featureflags!.dashboard!.view;
  const showPaymentMethod = true;

  return (
    <React.Fragment>
      <Title>Payment Requests</Title>
      <Table size="small" className={classes.smaller}>
        <TableHead>
          <TableRow>
            <TableCell>Date/Time</TableCell>
            {/* <TableCell>Exchange Order</TableCell> */}
            <TableCell>Seller Order</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Agency</TableCell>
            <TableCell>Reference</TableCell>
            {showAkaunNo && <TableCell>No Akaun</TableCell>}
            {showPaymentMethod && <TableCell>Payment Method</TableCell>}
            <TableCell>Payment Category</TableCell>
            <TableCell>Gross Amount</TableCell>
            <TableCell>Buyer Transacted Amount</TableCell>
            {showCharges && <TableCell>Charges</TableCell>}
            <TableCell>Bank</TableCell>
            <TableCell>Status</TableCell>
            {showOrderChan && <TableCell>Order Channel</TableCell>}
            {showUtm && <TableCell>UTM Source</TableCell>}
            {showUtm && <TableCell>UTM Media</TableCell>}
            {showUtm && <TableCell>UTM Campaign</TableCell>}
            {showUtm && <TableCell>UTM Term</TableCell>}
            {showUtm && <TableCell>UTM Content</TableCell>}
            {showExtraData && <TableCell>Extra Data</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(item => {
            let extra = (<></>);
            let extra2 = (<></>);
            if (item.extra !== undefined && item.extra.extra !== undefined && item.extra.extra.receipt_url !== undefined) {
                extra = (<div><br /><a target={"_blank"} rel={"noreferrer"} href={item.extra.extra.receipt_url}>Receipt</a></div>);
            }

            if (showCallbacks) {
              extra2 = (<CallbackLink fpx_seller_order_no={item.fpx_seller_order_no}>Callback</CallbackLink>);
            }

            return (
            <TableRow key={item.fpx_seller_order_no}>
              <TableCell>
                {item.fpx_seller_txn_time.replace(' +0800 UTC+8', '')}
              </TableCell>
              {/* <TableCell>{item.fpx_seller_ex_order_no}</TableCell> */}
              <TableCell>{item.fpx_seller_order_no}
                {extra}
                {extra2}
              </TableCell>
              <TableCell>{item.fpx_buyer_email}</TableCell>
              <TableCell>{item.agency}</TableCell>
              <TableCell>{item.ref_no}</TableCell>
              {showAkaunNo && <TableCell>{item.akaun_no}</TableCell>}
              {showPaymentMethod && <TableCell>{PaymentMethod.get(item.payment_method)?.name}</TableCell>}
              <TableCell>{item.payment_category}</TableCell>
              <TableCell align="right">{FormatCurrency(item.amount)}</TableCell>
              <TableCell align="right">{FormatCurrency(item.fpx_txn_amount)}</TableCell>
              {showCharges && <TableCell align="right">{item.add_charge}</TableCell>}
              <TableCell>
                {item.fpx_buyer_bank_id} - {item.fpx_buyer_bank_name}
                {showOrderChan && showExtraData && <div><br />{item.fpx_buyer_name}</div>}
              </TableCell>
              <TableCell>
                {item.fpx_debit_auth_code && item.fpx_debit_auth_code_desc && (
                  <span>
                    {item.fpx_debit_auth_code} - {item.fpx_debit_auth_code_desc}
                  </span>
                )}
              </TableCell>
              {showOrderChan && <TableCell>{item.order_chan}</TableCell>}
              {showUtm && <TableCell>{item.extra!.utm!.utm_source}</TableCell>}
              {showUtm && <TableCell>{item.extra!.utm!.utm_medium}</TableCell>}
              {showUtm && <TableCell>{item.extra!.utm!.utm_campaign}</TableCell>}
              {showUtm && <TableCell>{item.extra!.utm!.utm_term}</TableCell>}
              {showUtm && <TableCell>{item.extra!.utm!.utm_content}</TableCell>}

              {showExtraData && <TableCell>{item.extra_data}</TableCell>}
            </TableRow>
          )})}
        </TableBody>
      </Table>
    </React.Fragment>
  );
};

export default DefaultReport;
