import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from '../Title';
import { FPXRequestReport, ExtraMap, FPXZakatData, MaipsData } from '../../types';
// import { useStore } from '../../Store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    smaller: {
      padding: 2,
    },
    pre: {
      fontFamily: "monospace",
    }

  })
);

interface Props {
  data: FPXRequestReport[];
  onClick?: (order_id: string) => void;
}

const MAIPSReport: React.FC<Props> = ({ data }) => {
  const classes = useStyles();
  let data2 = data;
  let i = 0;
  for (i = 0; i < data2.length; i++) {
    if (data2[i].extra == null) {
      data2[i].extra = {
        zakat: {} as FPXZakatData,
        maips: {} as MaipsData,
      } as ExtraMap;
    }  
      
    if (data2[i]!.extra!.maips === undefined) {
      data2[i]!.extra!.maips = {
      } as MaipsData;
    }      
    if (data2[i]!.extra!.zakat === undefined) {
      data2[i]!.extra!.zakat = {
      } as FPXZakatData;
    }      
  }

  return (
    <React.Fragment>
      <Title>MAIPs Report Requests</Title>
      <Table size="small" className={classes.smaller}>
        <TableHead>
          <TableRow>
            <TableCell className={classes.pre}>Tarikh Transaksi</TableCell>
            <TableCell className={classes.pre}>No KP</TableCell>
            <TableCell className={classes.pre}>Nama</TableCell>
            <TableCell className={classes.pre}>No Telefon</TableCell>
            <TableCell className={classes.pre}>Emel</TableCell>
            <TableCell className={classes.pre}>Jenis Zakat</TableCell>
            <TableCell className={classes.pre}>No Rujukan Bayaran</TableCell>
            <TableCell className={classes.pre}>Amaun</TableCell>
            <TableCell className={classes.pre}>Saluran</TableCell>
            <TableCell className={classes.pre}>Alamat 1</TableCell>
            <TableCell className={classes.pre}>Alamat 2</TableCell>
            <TableCell className={classes.pre}>Alamat 3</TableCell>
            <TableCell className={classes.pre}>Poskod</TableCell>
            <TableCell className={classes.pre}>Bandar</TableCell>
            <TableCell className={classes.pre}>Negeri</TableCell>
            <TableCell className={classes.pre}>Keterangan</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data2.map(item => (
            <TableRow key={item.fpx_seller_order_no}>
              <TableCell className={classes.pre}>{item.extra!.maips!.tarikh_transaksi}</TableCell>
              <TableCell className={classes.pre}>{item.extra!.maips!.no_kp}</TableCell>
              <TableCell className={classes.pre}>{item.extra!.maips!.nama}</TableCell>
              <TableCell className={classes.pre}>{item.extra!.maips!.no_telefon}</TableCell>
              <TableCell className={classes.pre}>{item.extra!.maips!.emel}</TableCell>
              <TableCell className={classes.pre}>{item.extra!.maips!.jenis_zakat}</TableCell>
              <TableCell className={classes.pre}>{item.extra!.maips!.no_rujukan_bayaran}</TableCell>
              <TableCell className={classes.pre}>{item.extra!.zakat!.zakat_amount}</TableCell>
              <TableCell className={classes.pre}>SNP</TableCell>
              <TableCell className={classes.pre}>{item.extra!.maips!.alamat1}</TableCell>
              <TableCell className={classes.pre}>{item.extra!.maips!.alamat2}</TableCell>
              <TableCell className={classes.pre}>{item.extra!.maips!.alamat3}</TableCell>
              <TableCell className={classes.pre}>{item.extra!.zakat!.zakat_postcode}</TableCell>
              <TableCell className={classes.pre}>{item.extra!.zakat!.zakat_zone}</TableCell>
              <TableCell className={classes.pre}>{item.extra!.zakat!.zakat_state}</TableCell>
              <TableCell className={classes.pre}></TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
};

export default MAIPSReport;
