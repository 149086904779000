import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from '../Title';
import { FPXRequestReport, ExtraMap, FPXZakatData, MAIAMPData } from '../../types';
// import { useStore } from '../../Store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    smaller: {
      padding: 2,
    },

  })
);

interface Props {
  data: FPXRequestReport[];
  onClick?: (order_id: string) => void;
}

const MAIAMPReport: React.FC<Props> = ({ data }) => {
  const classes = useStyles();
  let data2 = data;
  let i = 0;
  for (i = 0; i < data2.length; i++) {
    if (data2[i].extra == null) {
      data2[i].extra = {
        zakat: {} as FPXZakatData,
        maiamp: {} as MAIAMPData,
      } as ExtraMap;
    }  
      
    if (data2[i]!.extra!.maiamp === undefined) {
      data2[i]!.extra!.maiamp = {
        jenis_zakat: "",
        district_name: "",
      } as MAIAMPData;
    }      
  }

  return (
    <React.Fragment>
      <Title>MAIAMP Report Requests</Title>
      <Table size="small" className={classes.smaller}>
        <TableHead>
          <TableRow>
            <TableCell>Date/Time</TableCell>
            {/* <TableCell>Exchange Order</TableCell> */}
            <TableCell>Seller Order</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Agency</TableCell>
            <TableCell>Reference</TableCell>
            <TableCell>Jenis Bayaran</TableCell>
            <TableCell>Kod Zakat</TableCell>
            <TableCell>Amount</TableCell>
            <TableCell>Bank</TableCell>
            <TableCell>Status</TableCell>
            {/* <TableCell>No KP</TableCell> */}
            {/* <TableCell>Nama</TableCell>
            <TableCell>No Telefon</TableCell>
            <TableCell>Alamat</TableCell>
            <TableCell>Poskod</TableCell>
            <TableCell>Kod Daerah</TableCell>
            <TableCell>Daerah</TableCell>
            <TableCell>Negeri</TableCell>
            <TableCell>Bilangan Tanggungan</TableCell>
            <TableCell>Kadar Fitrah</TableCell> */}
            <TableCell>Extra Data</TableCell>
            {/* <TableCell>Tarikh Bayaran</TableCell>
            <TableCell>No Transaksi</TableCell>
            <TableCell>Rujukan Order</TableCell>
            <TableCell>Nama Bank</TableCell>
            <TableCell>Jumlah Bayaran</TableCell> */}
            {/* <TableCell>Extra Data</TableCell> */}
          </TableRow>
        </TableHead>
        <TableBody>
          {data2.map(item => (
            <TableRow key={item.fpx_seller_order_no}>
              <TableCell>
                {item.fpx_seller_txn_time.replace(' +0800 UTC+8', '')}
              </TableCell>
              {/* <TableCell>{item.fpx_seller_ex_order_no}</TableCell> */}
              <TableCell>{item.fpx_seller_order_no}</TableCell>
              <TableCell>{item.fpx_buyer_email}</TableCell>
              <TableCell>{item.agency}</TableCell>
              <TableCell>{item.ref_no}</TableCell>
              <TableCell>{item.payment_category}</TableCell>
              <TableCell>{item.extra!.maiamp!.jenis_zakat}</TableCell>
              <TableCell align="right">{item.amount}</TableCell>
              <TableCell>
                {item.fpx_buyer_bank_id} - {item.fpx_buyer_bank_name}
              </TableCell>
              <TableCell>
                {item.fpx_debit_auth_code && item.fpx_debit_auth_code_desc && (
                  <span>
                    {item.fpx_debit_auth_code} - {item.fpx_debit_auth_code_desc}
                  </span>
                )}
              </TableCell>
              {/* <TableCell>{item.extra!.zakat!.zakat_ic_no}</TableCell> */}
              {/* <TableCell>{item.extra!.zakat!.zakat_name}</TableCell>
              <TableCell>{item.extra!.zakat!.zakat_phone}</TableCell>
              <TableCell>{item.extra!.zakat!.zakat_address}</TableCell>
              <TableCell>{item.extra!.zakat!.zakat_postcode}</TableCell>
              <TableCell>{item.extra!.zakat!.zakat_zone}</TableCell>
              <TableCell>{item.extra!.maidam!.district_name}</TableCell>
              <TableCell>{item.extra!.zakat!.zakat_state}</TableCell>
              <TableCell>{item.extra!.zakat!.zakat_dependant}</TableCell>
              <TableCell>{item.extra!.zakat!.zakat_rate}</TableCell> */}
              {/* <TableCell>{item.extra!.maidam!.tarikh_transaksi}</TableCell>
              <TableCell>{item.ref_no}</TableCell>
              <TableCell>{item.fpx_seller_order_no}</TableCell>
              <TableCell>{item.fpx_buyer_bank_name}</TableCell>
              <TableCell>{item.extra!.zakat!.zakat_amount}</TableCell> */}
              {/* <TableCell>{item.extra_data}</TableCell> */}
              <TableCell align="right">{item.extra_data}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
};

export default MAIAMPReport;
