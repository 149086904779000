import React from "react";
import { makeStyles, createStyles, Theme } from "@material-ui/core/styles";
import Table from "@material-ui/core/Table";
import TableBody from "@material-ui/core/TableBody";
import TableCell from "@material-ui/core/TableCell";
import TableHead from "@material-ui/core/TableHead";
import TableRow from "@material-ui/core/TableRow";
import Title from "../Title";
import {
  FPXRequestReport,
  ExtraMap,
  FPXZakatData,
  ZakatStat,
} from "../../types";
// import { useStore } from "../../Store";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    smaller: {
      padding: 2,
    },
  })
);

interface Props {
  data: FPXRequestReport[];
  onClick?: (order_id: string) => void;
}

const ZakatStatReport: React.FC<Props> = ({ data }) => {
  const classes = useStyles();
  let data2 = data;
  let i = 0;
  for (i = 0; i < data2.length; i++) {
    if (data2[i].extra == null) {
      data2[i].extra = {
        zakat: {} as FPXZakatData,
        zakatstat: {} as ZakatStat,
      } as ExtraMap;
    }
    if (data2[i]!.extra!.zakatstat === undefined) {
      data2[i]!.extra!.zakatstat = {} as ZakatStat;
    }
  }

  return (
    <React.Fragment>
      <Title>Zakat Stat Report Requests</Title>
      <Table size="small" className={classes.smaller}>
        <TableHead>
          <TableRow>
            <TableCell>Gender</TableCell>
            <TableCell>
              CollectionDate / <br /> CollectionTime
            </TableCell>
            <TableCell>Postcode</TableCell>
            <TableCell>District</TableCell>
            <TableCell>Zakat Type</TableCell>
            <TableCell>Zakat Amount</TableCell>
            <TableCell>Zakat Rate</TableCell>
            <TableCell>Zakat Dependant</TableCell>
            <TableCell>
              Agency / <br />
              FPXSellerOrderNo
            </TableCell>
            <TableCell>Name</TableCell>
            <TableCell>IC</TableCell>
            <TableCell>Address</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {data2.map((item) => (
            <TableRow key={item.fpx_seller_order_no}>
              <TableCell>{item.extra!.zakatstat!.gender}</TableCell>
              <TableCell>
                {item.extra!.zakatstat!.collection_date} <br />
                {item.extra!.zakatstat!.collection_time}
              </TableCell>
              <TableCell>{item.extra!.zakatstat!.postcode}</TableCell>
              <TableCell>{item.extra!.zakatstat!.district}</TableCell>

              <TableCell>{item.extra!.zakat!.zakat_type}</TableCell>
              <TableCell align="right">
                {item.extra!.zakat!.zakat_amount}
              </TableCell>
              <TableCell>{item.extra!.zakat!.zakat_rate}</TableCell>
              <TableCell>{item.extra!.zakat!.zakat_dependant}</TableCell>

              <TableCell>
                {item.agency} <br />
                {item.extra!.zakat!.fpx_seller_order_no}
              </TableCell>
              <TableCell>{item.extra!.zakat!.zakat_name}</TableCell>
              <TableCell>{item.extra!.zakat!.zakat_ic_no}</TableCell>
              <TableCell>{item.extra!.zakat!.zakat_address}</TableCell>
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
};

export default ZakatStatReport;
