import React from 'react';
import { useStore } from '../Store';

interface Props {
  fpx_seller_order_no: string;
}
  
const CallbackLink: React.FC<Props> = ({ fpx_seller_order_no }) => {
  const { state } = useStore();
  return (
    <p>
      <a rel="noreferrer" target="_blank" href={"/api/callback/v1/callback?fpx_seller_order_no="+fpx_seller_order_no+"&_jwt="+state.token}>Callback</a>
    </p>
  );
};

export default CallbackLink;
