import React, { useEffect, useCallback, useRef } from "react";
import Login from "./views/Login";
// import LoginBox from "./components/LoginBox";
import Dashboard from "./views/Dashboard";
// import Developer from "./views/Developer";
import {
  useStore,
  login,
  logout,
  updateInfo,
  updateFeatureflags,
} from "./Store";
import { Endpoint } from "./common";
import axios, { AxiosRequestConfig } from "axios";
// import {
//   HashRouter as Router,
//   Route,
//   Switch,
//   Redirect,
// } from "react-router-dom";
// import CssBaseline from "@material-ui/core/CssBaseline";
// import { Provider } from "react-redux";
// import configureStore from "./store/index";
// import qs from "querystring";

import firebase from "firebase";
import "firebase/auth";
import withFirebaseAuth, {
  WrappedComponentProps,
} from "react-with-firebase-auth";

import { firebaseConfig } from "./firebaseconfig";

// const { store } = configureStore();

let loginfirebase = false; // A debouncer to help not trigger things when things are happening async-ly.

const App = ({
  signInWithGoogle,
  signInWithEmailAndPassword,
  signOut,
  user,
}: WrappedComponentProps) => {
  const { state, dispatch } = useStore();
  const { token } = state;
  const previousTokenRef = useRef<string | null>(null);

  // Add mounted ref to track component state
  // const mounted = useRef(false);

  // useEffect(() => {
  //   mounted.current = true;
    
  //   // Cleanup function
  //   return () => {
  //     mounted.current = false;
  //   };
  // }, []);

  const postLogin = useCallback(async(token: string) => {
    dispatch(login({ token }));
  }, [dispatch]);

  const handleSignOut = useCallback(() => {
    signOut();
  }, [signOut]);

  useEffect(() => {
    if (!user) {
      return;
    }

    const unsubscribe = firebaseAppAuth.onIdTokenChanged(async (firebaseUser) => {
      if (!firebaseUser) {
        return;
      }

      const idToken = await firebaseUser.getIdToken();
      
      // Only proceed if the token is different from the previous one
      if (previousTokenRef.current !== idToken) {
        previousTokenRef.current = idToken;
        
        const params = new URLSearchParams();
        params.append('firebasetoken', idToken);
        axios.post(Endpoint.Login, params, {
          headers: {
            'Content-Type': 'application/x-www-form-urlencoded'
          }
        }).then((resp) => {
          if (resp.status === 200) {
            dispatch(login({ token: resp.data.access_token }));
          }
        });
      }
    });

    return () => unsubscribe();
  }, [user, dispatch, postLogin, token]);

  useEffect(() => {
    const fetchUserInfo = async () => {
      if (user != null && token === "" && loginfirebase) {
        handleSignOut();
      }

      if (!token) {
        return;
      }

      // user_info
      try {
        const config: AxiosRequestConfig = {
          method: "get",
          url: Endpoint.UserInfo,
          headers: { Authorization: `Bearer ${token}` },
        };
        const resp = await axios(config);
        if (resp.status !== 200) {
          dispatch(logout());
          return;
        }
        loginfirebase = true;
        dispatch(updateInfo(resp.data));
      } catch (e) {
        dispatch(logout());
      }

      // featureflag
      try {
        const config: AxiosRequestConfig = {
          method: "get",
          url: "/featureflags.json",
          headers: { Authorization: `Bearer ${token}` },
        };
        const resp = await axios(config);
        if (resp.status !== 200) {
          return;
        }
        // if (mounted.current) {
          dispatch(updateFeatureflags(resp.data));
        // }
      } catch (e) {
        // dispatch(logout());
      }
    };

    fetchUserInfo();
  }, [user, dispatch, token, postLogin, handleSignOut]);

  let signInWithGoogle2 = function () {
    loginfirebase = false;
    signInWithGoogle();
  };

  const signInWithPassword = (email: string, password: string) => {
    // createUserWithEmailAndPassword(email, password);
    signInWithEmailAndPassword(email, password);
  };

  if (state.token && state.info) {
    return <Dashboard />;
  }
  return (
    <Login
      setToken={postLogin}
      signInWithPassword={signInWithPassword}
      signInWithGoogle={signInWithGoogle2}
    />
  );

};

const firebaseApp = firebase.initializeApp(firebaseConfig);
const firebaseAppAuth = firebaseApp.auth();
const providers = {
  googleProvider: new firebase.auth.GoogleAuthProvider(),
};

export default withFirebaseAuth({
  providers,
  firebaseAppAuth,
})(App);
