import React from 'react';
import { makeStyles, createStyles, Theme } from '@material-ui/core/styles';
import Table from '@material-ui/core/Table';
import TableBody from '@material-ui/core/TableBody';
import TableCell from '@material-ui/core/TableCell';
import TableHead from '@material-ui/core/TableHead';
import TableRow from '@material-ui/core/TableRow';
import Title from '../Title';
import { FPXRequestReport } from '../../types';
import { useStore } from '../../Store';

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexGrow: 1,
    },
    smaller: {
      padding: 2,
    },
    
  })
);

interface Props {
  data: FPXRequestReport[];
  onClick?: (order_id: string) => void;
}

const IkhlasReport: React.FC<Props> = ({ data }) => {
  const classes = useStyles();
  const { state } = useStore();
  const showAkaunNo = state!.featureflags!.dashboard!.showAkaunNo;
  const showExtraData = state!.featureflags!.dashboard!.showExtraData;
  const showCharges = state!.featureflags!.dashboard!.showCharges;
  // const selectView =  state!.featureflags!.dashboard!.selectView;
  // const view = state!.featureflags!.dashboard!.view;


  return (
    <React.Fragment>
      <Title>Payment Requests (Ikhlas)</Title>
      <Table size="small" className={classes.smaller}>
        <TableHead>
          <TableRow>
            <TableCell>Date/Time</TableCell>
            {/* <TableCell>Exchange Order</TableCell> */}
            <TableCell>Seller Order</TableCell>
            <TableCell>Email</TableCell>
            <TableCell>Agency</TableCell>
            <TableCell>Reference</TableCell>
            {showAkaunNo && <TableCell>No Akaun</TableCell>}
            <TableCell>Jenis Bayaran</TableCell>
            <TableCell>Amount</TableCell>
            {showCharges && <TableCell>FPX Amount</TableCell>}
            {showCharges && <TableCell>Charges</TableCell>}
            <TableCell>Bank</TableCell>
            <TableCell>Status</TableCell>
            <TableCell>BIG Member ID</TableCell>
            {showExtraData && <TableCell>Extra Data</TableCell>}
          </TableRow>
        </TableHead>
        <TableBody>
          {data.map(item => (
            <TableRow key={item.fpx_seller_order_no}>
              <TableCell>
                {item.fpx_seller_txn_time.replace(' +0800 UTC+8', '')}
              </TableCell>
              {/* <TableCell>{item.fpx_seller_ex_order_no}</TableCell> */}
              <TableCell>{item.fpx_seller_order_no}</TableCell>
              <TableCell>{item.fpx_buyer_email}</TableCell>
              <TableCell>{item.agency}</TableCell>
              <TableCell>{item.ref_no}</TableCell>
              {showAkaunNo && <TableCell>{item.akaun_no}</TableCell>}
              <TableCell>{item.payment_category}</TableCell>
              <TableCell align="right">{item.amount}</TableCell>
              {showCharges && <TableCell align="right">{item.fpx_txn_amount}</TableCell>}
              {showCharges && <TableCell align="right">{item.add_charge}</TableCell>}
              <TableCell>
                {item.fpx_buyer_bank_id} - {item.fpx_buyer_bank_name}
              </TableCell>
              <TableCell>
                {item.fpx_debit_auth_code && item.fpx_debit_auth_code_desc && (
                  <span>
                    {item.fpx_debit_auth_code} - {item.fpx_debit_auth_code_desc}
                  </span>
                )}
              </TableCell>
              <TableCell>
                {item.extra && item.extra.ikhlas && item.extra.ikhlas.big_member_id && (
                  <span>
                    {item.extra.ikhlas.big_member_id}
                  </span>
                )}
              </TableCell>
              {showExtraData && <TableCell>{item.extra_data}</TableCell>}
            </TableRow>
          ))}
        </TableBody>
      </Table>
    </React.Fragment>
  );
};

export default IkhlasReport;
