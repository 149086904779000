import React, { useEffect } from 'react';
import { createStyles, makeStyles, Theme } from '@material-ui/core/styles';
import axios, { AxiosRequestConfig } from 'axios';
import FileDownload from 'js-file-download';
import FPXRequestList from '../components/FPXRequestList';
import QueryAction, { QueryParameters } from '../components/QueryAction';
import PaginationAction from '../components/PaginationAction';
import FPXSummary from '../components/FPXSummary';
import { Endpoint, SnapNPayReportAPI } from '../common';
import { FPXRequestReport, FPXRequestStats, Agency } from '../types';
import moment from 'moment';
// import { useDebounce } from 'use-lodash-debounce';
import Table from '@material-ui/core/Table';
import TableHead from '@material-ui/core/TableHead';


interface Props {
  token: string;
  username: string;
  accountType: 'v1' | 'v2';
  initView: string;
}

// interface State {
//   start: string;
//   end: string;
//   seller_order_no: string | null;
//   agency: string;
//   page: number;
//   items: number;
//   is_approved: boolean;
//   is_download: boolean;
//   format?: string;
//   view: string;
//   noload: boolean;
//   is_anonymized: boolean;
// }

// interface Parameters {
//   start: string;
//   end: string;
//   seller_order_no: string;
//   agency: string;
//   is_approved: boolean;
//   is_download: boolean;
//   format?: string;
//   view: string;
//   is_anonymized: boolean;
// }

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      flexShrink: 0,
      // marginLeft: theme.spacing(2.5),
    }
  })
);

const FPXRequests: React.FC<Props> = ({ token, username, initView, accountType }) => {
  const classes = useStyles();
  const [isLoading, setIsLoading] = React.useState<boolean>(true);
  const [data, setData] = React.useState<FPXRequestReport[]>([]);
  const [info, setInfo] = React.useState<FPXRequestStats>({
    page: 0,
    items_per_page: 20,
    count: 0,
    total_pages: 0,
    txn_amount: 0,
    txn_charges: 0,
    txn_summary: [],
  });

  const today = moment();
  const tomorrow = moment().add(1, 'day');
  let initialQuery : QueryParameters;
  let defaultStart = today.format('YYYY/MM/DD');
  let defaultEnd = tomorrow.format('YYYY/MM/DD');
  initialQuery = {
    page: 0,
    items: 20,
    agency: '',
    start: defaultStart,
    end: defaultEnd,
    seller_order_no: '',
    is_approved: true,
    is_download: false,
    format: 'json',
    view: initView,
    noload: false,
    is_anonymized: false,
  } as QueryParameters;
  if (window.location.hash !== "") {
    let h = window.location.hash;
    let s = JSON.parse(decodeURI(h.substring(1)));
  
    initialQuery = s;
  }

  const [state, setState] = React.useState<QueryParameters>(() => {
    return initialQuery;
  });
  
  if (initView !== state.view) {
    let view = initView;
    setState({...state, view,});
  }

  const [agencies, setAgencies] = React.useState<Agency[]>([]);

  const onViewChanged = (args: {view: string}) => {
    const {
      view,
    } = args;
    let noload = true;
    setState({
      ...state,
      view,
      noload,
    });
  };

  const onClick = (args: QueryParameters) => {
    const {
      start,
      end,
      seller_order_no,
      agency,
      is_approved,
      is_download,
      format,
      view,
      is_anonymized,
    } = args;

    if (isLoading && !is_download &&
      start === state.start &&
      end === state.end &&
      seller_order_no === state.seller_order_no &&
      agency === state.agency &&
      is_approved === state.is_approved &&
      (format === state.format || format === undefined) &&
      state.page === 0
    ) {
      // Don't change state if nothing changed.
      return;
    }
    
    // console.log("state changed", args, state);
    let noload = false;

    setState({
      ...state,
      start,
      end,
      seller_order_no,
      agency,
      is_approved,
      is_download,
      page: 0,
      format,
      view,
      noload,
      is_anonymized,
    });
  };

  const onChangePage = (
    event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
    newPage: number
  ) => {
    let noload = false;
    if (event !== null) {
      setState({ ...state, page: newPage, noload });
    }
  };

  // const debouncedState = useDebounce(state, 200);

  useEffect(() => {
    const fetchList = async () => {
      const {
        page,
        items,
        start,
        end,
        seller_order_no,
        agency,
        is_approved,
        is_download,
        format,
        view,
        noload,
        is_anonymized,
      } = state;
      if (noload)  {
        return;
      }
      // setIsLoading(true);
      try {
        let output = format;

        if (!is_download) {
          output = 'json';
        }
        const config: AxiosRequestConfig = {
          method: 'get',
          url: Endpoint.PaymentReport,
          params: {
            output: output,
            page: is_download ? '-1' : String(page),
            items: is_download ? '-1' : String(items),
            seller_order_no,
            start,
            end,
            agency,
            is_approved: is_approved ? '1' : '0',
            is_download: is_download ? '1' : '0',
            view: view,
            anonymize: is_anonymized ? '1' : '0',
          },
          headers: { Authorization: `Bearer ${token}` },
        };

        if (is_download) {
          config.responseType = 'arraybuffer';
        }

        const resp = await axios(config);
        if (resp.status === 200) {
          if (is_download) {
            if (resp.data) {
              FileDownload(resp.data, 'fpx_report.' + format);
            }
          } else {
            if (resp.data.data) {
              setData(resp.data.data);
            }

            if (resp.data.stats) {
              setInfo(resp.data.stats);
            }
          }

          document.location.hash = JSON.stringify(state);
        }
      } catch (err) {
        console.log(err);
      }

      // setTimeout(() => setIsLoading(false), 150);
      if (is_download) {
        setState({ ...state, is_download: false });
      }
      setIsLoading(false);
    };

    fetchList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [debouncedState, token]);
  }, [state, token]);

  useEffect(() => {
    const whitelist = ['kpkt', 'KPTM', 'administrator'];
    if (true || whitelist.indexOf(username) >= 0 || accountType === 'v2') {
      if (agencies.length === 0 && token !== '') {
        (async () => {
          const api = new SnapNPayReportAPI(token);
          try {
            const agencyList: Agency[] = await api.fetchAgencyList();
            if (agencyList.length > 1) {
              // console.log("Setting agencies", agencyList);
              setAgencies(agencyList);
            }
          } catch (err) {
            console.log(err);
          }
        })();
      }
    }
  }, [username, token, state, accountType, agencies]);

  let content;
  if (isLoading) {
    content = <p>Loading</p>;
  } else {
    content = (
      <React.Fragment>
        <FPXSummary {...info} />
        {/* FIXME: UI-wise, pager should be under FPXRequestList */}
        <Table className={classes.root} aria-label="">
          <TableHead>
            <PaginationAction
              count={info.count}
              page={info.page}
              rowsPerPage={info.items_per_page}
              onPageChange={onChangePage}
            />
          </TableHead>
        </Table>          
        <FPXRequestList data={data} view={state.view} />
          
      </React.Fragment>
    );
  }

  return (
    <React.Fragment>
      <QueryAction
        onClick={onClick}
        agencies={agencies}
        isLoading={isLoading}
        initialParam={initialQuery}
        onViewChanged={onViewChanged}
      />
      {content}
    </React.Fragment>
  );
};

export default React.memo(FPXRequests);
