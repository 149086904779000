import React from 'react';

interface State {
  token?: string;
  info?: Info;
  featureflags?: Featureflags;
}

interface Info {
  username?: string;
  email?: string;
  agency_code?: string;
  agency?: string;
  account_type?: string;
  image?: string;
  scopes?: string[];
}

// Synced with featureflags/struct.go
interface Featureflags {
  debug?: boolean;
  dashboard?: {
    showCharges?: boolean;
    showAkaunNo?: boolean;
    showExtraData?: boolean;
    showOrderChan?: boolean;
    selectView?: boolean;
    multiAgency?: boolean;
    showCallbacks?: boolean;
    view?: string;
    views?: string[];
  }
  dev?: boolean;
}

const initialState = (): State => {
  let token = sessionStorage.getItem('token');
  if (!token) {
    token = '';
  }

  return { token };
};

type LoginActions =
  | { type: 'SESSION_LOGIN'; state: State }
  | { type: 'SESSION_LOGOUT' }
  | { type: 'SESSION_INFO'; info: Info }
  | { type: 'FEATUREFLAGS_SET'; featureflags: Featureflags }
  | { type: 'REPORT_VIEW_CHANGE'; view: string }

export const login = (state: State): LoginActions => {
  return { type: 'SESSION_LOGIN', state };
};

export const updateInfo = (info: Info): LoginActions => {
  return { type: 'SESSION_INFO', info };
};

export const logout = (): LoginActions => {
  return { type: 'SESSION_LOGOUT' };
};

export const updateFeatureflags = (featureflags: Featureflags): LoginActions => {
  return { type: 'FEATUREFLAGS_SET', featureflags };
};

export const updateReportView = (view: string): LoginActions => {
  return { type: 'REPORT_VIEW_CHANGE', view };
};

const reducer = (prevState: State, action: LoginActions): State => {
  // console.log('reducer', action, prevState);
  switch (action.type) {
    case 'SESSION_LOGIN':
      const { state } = action;
      const { token } = state;

      if (token) {
        sessionStorage.setItem('token', token);
      }
      return {...prevState, ...state };
    case 'SESSION_INFO':
      const { info } = action;
      return { ...prevState, info: { ...info } };
    case 'FEATUREFLAGS_SET':
      const { featureflags } = action;
      return { ...prevState, featureflags: { ...featureflags } };
    case 'REPORT_VIEW_CHANGE':
      const { view } = action;
      let ff = prevState.featureflags;
      if (ff!.dashboard !== undefined) {
        ff!.dashboard!.view = view;
      }
      return { ...prevState, featureflags: { ...ff } };
    case 'SESSION_LOGOUT':
      sessionStorage.clear();
      return initialState();
    default:
      return prevState;
  }
};

// [State, React.Dispatch<LoginActions>]
export const Store = React.createContext({
  state: initialState(),
} as {
  state: State;
  dispatch: React.Dispatch<LoginActions>;
});

// const redux = React.useReducer(reducer, initialState());
export const StoreProvider: React.FC<{
  props?: React.ReactChildren;
}> = props => {
  const [state, dispatch] = React.useReducer(reducer, initialState());
  return (
    <Store.Provider value={{ state, dispatch }}>
      {props.children}
    </Store.Provider>
  );
};

export const useStore = () => React.useContext(Store);
useStore.Context = Store;
useStore.Provider = StoreProvider;

export default Store;
