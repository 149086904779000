import React, { useRef } from "react";
import { createStyles, makeStyles, Theme } from "@material-ui/core/styles";
import { green } from "@material-ui/core/colors";
import Button from "@material-ui/core/Button";
import Divider from "@material-ui/core/Divider";
import CloudDownload from "@material-ui/icons/CloudDownload";
import Search from "@material-ui/icons/Search";
import FormControlLabel from "@material-ui/core/FormControlLabel";
import Checkbox from "@material-ui/core/Checkbox";
import Loading from "@material-ui/core/CircularProgress";
import Select from "@material-ui/core/Select";
import TextField from "@material-ui/core/TextField";
import MenuItem from "@material-ui/core/MenuItem";
import DatePicker from "./DatePicker";
import { Agency } from "../types";
import DownloadButton from "./DownloadButton";
import moment from "moment";
import { useStore, updateReportView } from "../Store";
import { reportviews } from "../datadictionary";

const useStyles = makeStyles((theme: Theme) =>
  createStyles({
    root: {
      marginBottom: theme.spacing(2),
    },
    button: {
      margin: theme.spacing(1),
    },
    leftIcon: {
      marginRight: theme.spacing(1),
    },
    rightIcon: {
      marginLeft: theme.spacing(1),
    },
    iconSmall: {
      fontSize: 20,
    },
    datepicker: {
      marginRight: theme.spacing(10),
      marginTop: theme.spacing(0),
    },
    buttonProgress: {
      color: green[500],
      position: "absolute",
      top: "50%",
      left: "50%",
      marginTop: -12,
      marginLeft: -12,
    },
    checkbox: {
      marginLeft: theme.spacing(2),
    },
    select: {
      minWidth: theme.spacing(30),
      marginLeft: theme.spacing(2),
    },
    orderID: {
      marginTop: theme.spacing(0),
      marginRight: theme.spacing(2),
    },
    divider: {
      backgroundColor: "#fff",
    },
    formControl: {
      margin: theme.spacing(1),
      minWidth: 120,
    },
  })
);

export interface QueryParameters {
  start: string;
  end: string;
  seller_order_no: string | null;
  agency: string;
  page: number;
  items: number;
  is_approved: boolean;
  is_download: boolean;
  format?: string;
  view: string;
  noload: boolean;
  is_anonymized: boolean;
}

interface Props {
  onDownload?: () => void;
  isLoading: boolean;
  onClick: (args: QueryParameters) => void;
  agencies: Agency[];
  initialParam: QueryParameters;
  onViewChanged: (args: { view: string }) => void;
}

// Sync with FPXRequestList.tsx

const QueryAction: React.FC<Props> = (props) => {
  const classes = useStyles();

  const [startDate, setStartDate] = React.useState<string>(
    // today.format('YYYY/MM/DD')
    props.initialParam.start
  );
  const [endDate, setEndDate] = React.useState<string>(
    // tomorrow.format('YYYY/MM/DD')
    props.initialParam.end
  );

  const [isApproved, setApproved] = React.useState<boolean>(props.initialParam.is_approved);
  const [selectedAgency, setSelectedAgency] = React.useState<string>(props.initialParam.agency);
  const [sellerOrderNo, setSellerOrderNo] = React.useState<string>(props.initialParam.seller_order_no ? props.initialParam.seller_order_no : "");
  const [isAnonymized, setAnonymized] = React.useState<boolean>(props.initialParam.is_anonymized);

  // props.initialParam

  const { state, dispatch } = useStore();

  // const selectView =  "";//state!.featureflags!.dashboard!.selectView;
  // const view = state!.featureflags!.dashboard!.view;
  // let view = "default";
  let selectView = false;
  let view = "default";
  if (props.initialParam.view) {
    view = props.initialParam.view;
  }
  let views = [ view ];
  if (state!.featureflags) {
    if (state!.featureflags!.dashboard!.view !== undefined) {
      view = state!.featureflags!.dashboard!.view;
    }

    if (state!.featureflags!.dashboard!.selectView) {
      selectView = true;

      if (state!.featureflags!.dashboard!.views !== undefined) {
        views = state!.featureflags!.dashboard!.views;
      }
    }
  }

  const onChecked = (event: React.ChangeEvent<HTMLInputElement>) => {
    setApproved(event.target.checked);
  };
  const onChecked2 = (event: React.ChangeEvent<HTMLInputElement>) => {
    setAnonymized(event.target.checked);
  };

  let agencyViewMap = new Map<string, string>();
  props.agencies.forEach((e) => {
    agencyViewMap.set(e.code, e.report_view);
  });

  const onSearch = () => {
    props.onClick({
      start: startDate,
      end: endDate,
      seller_order_no: sellerOrderNo,
      agency: selectedAgency,
      is_approved: isApproved,
      is_download: false,
      view: view,
      is_anonymized: isAnonymized,
    } as QueryParameters);
  };
  const onViewChanged = (view: string) => {
    props.onViewChanged({ view: view });
  };

  const onDownload = (format: string) => {
    props.onClick({
      start: startDate,
      end: endDate,
      seller_order_no: sellerOrderNo,
      agency: selectedAgency,
      is_approved: isApproved,
      is_download: true,
      format: format,
      view: view,
      is_anonymized: isAnonymized,
    } as QueryParameters);
  };

  const progress = props.isLoading ? (
    <Loading size={24} className={classes.buttonProgress} />
  ) : null;

  const onDateRangeChange = (start: string | null, end: string | null) => {
    if (start !== null && end !== null) {
      setStartDate(start);
      setEndDate(end);
      props.onClick({
        start: start,
        end: end,
        seller_order_no: sellerOrderNo,
        agency: selectedAgency,
        is_approved: isApproved,
        is_download: false,
        view: view,
        is_anonymized: isAnonymized,
      } as QueryParameters);
    }
  };

  // We need to use useRef to use onClick without React complaining that onClick needs 
  // to be a dependency of the useEffect. This is because onClick is a prop and it should
  // not be a dependency of the useEffect. If we add it as a dependency, the useEffect will
  // run every time onClick changes, which is not what we want.
  const onClick = useRef<(args: QueryParameters) => void | null>(props.onClick);
  React.useEffect(() => {
    var qp = {
      start: startDate,
      end: endDate,
      seller_order_no: sellerOrderNo,
      agency: selectedAgency,
      is_approved: isApproved,
      is_download: false,
      view: view,
      is_anonymized: isAnonymized,
    } as QueryParameters;
    onClick.current(qp);
  }, [isApproved, selectedAgency, startDate, endDate, sellerOrderNo, isAnonymized, view]);

  return (
    <div className={classes.root}>
      <DatePicker
        start={moment(props.initialParam.start.replace(/\//g, "-")).toDate()}
        end={moment(props.initialParam.end.replace(/\//g, "-")).toDate()}
        onClick={onDateRangeChange}
      />

      <FormControlLabel
        className={classes.checkbox}
        control={
          <Checkbox value="1" checked={isApproved} onChange={onChecked} />
        }
        label="Approved"
      />

      <Button
        variant="contained"
        color="primary"
        className={classes.button}
        disabled={props.isLoading}
        onClick={onSearch}
      >
        {progress}
        Search <Search className={classes.rightIcon} />
      </Button>

      <DownloadButton
        color="secondary"
        className={classes.button}
        disabled={props.isLoading}
        onClick={onDownload}
      >
        {progress}
        <CloudDownload className={classes.rightIcon} />
      </DownloadButton>

      <Divider className={classes.divider} light />

      <TextField
        className={classes.orderID}
        style={{ marginRight: 0 }}
        placeholder="Seller Order No"
        margin="normal"
        onChange={(e) => {
          setSellerOrderNo(e.target.value);
        }}
        value={sellerOrderNo}
        variant="outlined"
      />

      {props.agencies.length > 0 && (
        <Select
          className={classes.select}
          variant="outlined"
          value={selectedAgency}
          displayEmpty
          autoWidth
          onChange={(event) => {
            setSelectedAgency(event.target.value as string);

            if (views.length > 1) {
              let agencyView = agencyViewMap.get(event.target.value as string);
              if (agencyView === undefined) {
                agencyView = "default";
              }
              dispatch(updateReportView(agencyView));
              onViewChanged(agencyView);
            }
          }}
        >
          <MenuItem value="">All</MenuItem>
          {props.agencies.map(({ code, name }) => {
            return (
              <MenuItem key={code} value={code}>
                {code} - {name}
              </MenuItem>
            );
          })}
        </Select>
      )}

      {selectView && (
        <Select
          className={classes.select}
          variant="outlined"
          value={view}
          displayEmpty
          autoWidth
          onChange={(event) => {
            let v = event.target.value as string;
            dispatch(updateReportView(v));
            onViewChanged(v);
          }}
        >
          {reportviews.map(({ code, name }) => {
            if (views.indexOf(code) > -1) {
              return (
                <MenuItem key={code} value={code}>
                  {code} - {name}
                </MenuItem>
              );
            }
            return (<></>);
          })}
        </Select>
      )}

      {/* TODO this needs its own flag */}
      {selectView && (<FormControlLabel
        className={classes.checkbox}
        control={
          <Checkbox value="0" checked={isAnonymized} onChange={onChecked2} />
        }
        label="Anonymized"
      />)}

    </div>
  );
};

export default QueryAction;
